import React, {useEffect, useState} from 'react';
import {
    Drawer,
    Toolbar,
    List,
    ListItem,
    Link, Grid, Divider, TextField, Button, Table, TableHead, TableRow, TableCell, TableBody
} from '@mui/material';

import Marquee from "react-fast-marquee";
import {constants} from "../firebase";
import {useAuth} from "../context/authContext";
import {DeleteForeverSharp} from "@mui/icons-material";

const drawerWidth = 140;

function Main() {
    const authContext = useAuth();

    const [dynamicUrlsList, setDynamicUrlsList] = useState([]);

    useEffect( () => {
        async function runEffect() {
            fetch(`${constants.functionsBaseUrl}/internals/dynamicurls`, {
                headers: {
                    Authorization: `Bearer ${await authContext.currentUser.getIdToken()}`,
                },
            }).then(res => res.json()).then(res => {
                if (res.code === 200) {
                    setDynamicUrlsList(res.data);
                }
            });
        }

        runEffect();
    }, [authContext.currentUser]);

    const createDynamicUrls = async (event) => {
        event.preventDefault();
        const url = event.target.elements.url.value;
        const label = event.target.elements.label.value;

        fetch(`${constants.functionsBaseUrl}/internals/dynamicurls`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${await authContext.currentUser.getIdToken()}`,
            },
            body: JSON.stringify({ url, label })
        }).then(res => res.json()).then(res => {
            if (res.code === 200) {
                window.location.reload();
            }
        });
    }

    const deleteLink = async (id) => {
        fetch(`${constants.functionsBaseUrl}/internals/dynamicurls/${id}`, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${await authContext.currentUser.getIdToken()}`,
            }
        }).then(res => res.json()).then(res => {
            if (res.code === 200) {
                window.location.reload();
            }
        });
    }

    return (
        <div style={{ display: 'flex' }}>
            <Drawer
                variant="permanent"
                style={{ width: drawerWidth, flexShrink: 0 }}
                PaperProps={{ style: { width: drawerWidth } }}
            >
                <Toolbar />
                <div style={{ overflow: 'auto' }}>
                    <List>
                        <ListItem>
                            {
                                [{name: "Dynamic Link", link: "/dynamiclink"}].map(entry => (
                                    <Link to={entry.url} style={{textDecoration: "none", cursor: "pointer"}}>{entry.name}</Link>
                                ))
                            }
                        </ListItem>
                    </List>
                </div>
            </Drawer>
            <Grid spacing={20} padding={3} style={{width: "100vw"}}>
                {/*<Grid item xs={12}>*/}
                {/*    <Marquee>*/}
                {/*        <h6>New User: Karthik Nedunchezhiyan</h6>*/}
                {/*    </Marquee>*/}
                {/*</Grid>*/}
                <Grid item xs={12}>
                    <form autoComplete="off" onSubmit={createDynamicUrls}>
                        <h2>Create a Dynamic Link</h2>
                        <TextField
                            label="url"
                            required
                            variant="outlined"
                            color="secondary"
                            type="url"
                            name="url"
                            sx={{mb: 3}}
                            fullWidth
                        />
                        <TextField
                            label="label"
                            name="label"
                            required
                            variant="outlined"
                            color="secondary"
                            type="text"
                            fullWidth
                            sx={{mb: 3}}
                        />
                        <Button variant="outlined" color="secondary" type="submit">Create</Button>
                    </form>
                    <br/>
                    <Divider style={{width: "100%"}}></Divider>

                    { dynamicUrlsList.length > 0 && <>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>URL</TableCell>
                                    <TableCell align="right">Connection</TableCell>
                                    <TableCell align="right">Label</TableCell>
                                    <TableCell align="right">Hits</TableCell>
                                    <TableCell align="right">Created On</TableCell>
                                    <TableCell align="right">Last Access</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    dynamicUrlsList.map((entry, index) => (
                                        <TableRow key={index}>
                                            <TableCell component="th" scope="row">
                                                {entry.url}
                                            </TableCell>
                                            <TableCell align="right">{entry.dynamicUrl}</TableCell>
                                            <TableCell align="right">{entry.label}</TableCell>
                                            <TableCell align="right">{entry.hits}</TableCell>
                                            <TableCell align="right">{
                                                entry.timestamp ?
                                                    (new Date(
                                                        (entry.timestamp._seconds * 1000) + (entry.timestamp._nanoseconds / 1000000)
                                                    )).toString().split("(")[0].trim()
                                                    : "Not Available"
                                            }</TableCell>
                                            <TableCell align="right">{
                                                entry.lAccess ?
                                                    (new Date(
                                                        (entry.lAccess._seconds * 1000) + (entry.lAccess._nanoseconds / 1000000)
                                                    )).toString().split("(")[0].trim()
                                                    : "Not Available"
                                            }</TableCell>
                                            <TableCell align="right" style={{color: "#ed5e68"}} onClick={()=> deleteLink(entry.id)}>
                                                <DeleteForeverSharp/>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </> }

                    { dynamicUrlsList.length === 0 && <>
                        <h6>No Dynamic URLs available to display! Create a Dynamic URL to see the analytics here</h6>
                    </> }
                </Grid>
            </Grid>
        </div>
    );
}

export default Main;