import {useAuth} from "../context/authContext";
import {useNavigate} from "react-router-dom";

const LogOutPage = () => {
    const authContext = useAuth();
    const navigate = useNavigate();

    // TODO : punch loader
    authContext.logOut().then(_ => {
        window.location.href = "https://www.myoverlap.com";
    }).catch(e => {
        console.error(e);
    });

    return (
        <></>
    );
}

export default LogOutPage