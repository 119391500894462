import React, {Fragment} from 'react';
import './App.css';
import Main from './components/Main';
import {AuthProvider} from "./context/authContext";
import PrivateRoute from "./components/Route/PrivateRoute";
import {Route, Routes} from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import LogOutPage from "./pages/LogoutPage";

function App() {
    return (
        <Fragment>
            <AuthProvider>
                <Routes>
                    <Route element={<PrivateRoute />}>
                        <Route path="/" element={<Main/>} />
                    </Route>
                    <Route path="/login" element={<LoginPage/>} />
                    <Route path="/logout" element={<LogOutPage />} />
                </Routes>
            </AuthProvider>
        </Fragment>
    )
}

export default App;
