import firebase from "firebase/compat/app";
import {getAuth} from "firebase/auth";

const app = firebase.initializeApp({
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID
});

export const auth = getAuth(app);
export const constants = {
    functionsBaseUrl: process.env.REACT_APP_FUNCTIONS_BASE_URL
};

export default app;