import react, {useEffect} from "react";
import {auth} from "../firebase";
import {
    onAuthStateChanged,
    signOut,
    signInWithPopup,
    GoogleAuthProvider,
    RecaptchaVerifier
} from "firebase/auth";

const AuthContext = react.createContext();

export function useAuth() {
    return react.useContext(AuthContext);
}

export function AuthProvider({children}) {
    function logInUsingGoogle() {
        return signInWithPopup(auth, new GoogleAuthProvider());
    }

    function recaptchaVerifier(containerID, callback) {
        const recaptchaVerifier = new RecaptchaVerifier(auth, containerID, {
            'size': 'normal', // or 'invisible', depending on your preference
            'callback': (response) => {
                recaptchaVerifier.clear();
                callback(response);
            }
        });

        recaptchaVerifier.render();
    }

    function logOut() {
        return signOut(auth);
    }

    const [currentUser, setCurrentUser] = react.useState(false);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, user => {
            setCurrentUser(user);
        });
        return () => {
            unsubscribe();
        };
    }, [setCurrentUser]);

    return (
        <AuthContext.Provider
            value={{
                currentUser,
                setCurrentUser,
                logOut,
                logInUsingGoogle,
                recaptchaVerifier
            }}>
            {children}
        </AuthContext.Provider>
    );
}