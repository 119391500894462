import {useAuth} from "../../context/authContext";
import {Navigate, Outlet} from 'react-router-dom';
export default function PrivateRoute() {
    const authContext = useAuth();

    if (authContext.currentUser === false) {
        /***
         * If the control reached here, it means we are on middle of verifying the user session. Until authentication API verifies the
         * session, Page loader will be shown.
         * */
        return <></>;
    }

    if (authContext.currentUser === null) {
        /***
         * If the control reached here, it means we have verified the user session, and we found user session is not-valid. So, redirect ot
         * login page.
         * */
        return <Navigate to={"/login"}/>;
    }

    return <Outlet/>;
}