import {useAuth} from "../context/authContext";
import {useEffect} from "react";

const LoginPage = () => {
    const authContext = useAuth();

    useEffect(() => {
        if (authContext.currentUser === null) authContext.logInUsingGoogle();
        else window.location.replace('/');
    }, [authContext.currentUser]);

    return (
        <></>
    );
}

export default LoginPage;